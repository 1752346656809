import { Box, Button, TextField, Typography } from "@mui/material";
import { navigate } from "gatsby";
import React, { useState } from "react";

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const Subscribe = () => {
  const [name, setName] = useState();
  const [email, setEmail] = useState();

  const handleSubmit = (e: React.SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "subscribe", ...{ name, email } }),
    })
      .then(() => navigate("/thanks/"))
      .catch((error) => alert(error));
  };

  const handleNameChange = (e) => setName(e.target.value);
  const handleEmailChange = (e) => setEmail(e.target.value);

  return (
    <div>
      <section id="subscribe" className="p-6 flex flex-col items-center">
        <div className="flex flex-col justify-center items-center sm:w-full m-auto gap-2 pb-10 px-1">
          <Typography
            className="text-6xl text-center"
            fontFamily={"Billy Ohio Dua"}
          >
            Sign up for my newsletter
          </Typography>
          <Typography variant="body1" className="text-center max-w-[550px]">
            New subscribers receive <em>My Unfurling</em> bonus chapters and the{" "}
            <em>Yes, You Can</em> self-publishing workbook. You'll also get
            sneak peeks into upcoming projects and other fun content delivered
            right to your inbox.
          </Typography>
        </div>
        <form
          name="subscribe"
          method="POST"
          data-netlify="true"
          netlify-honeypot="bot-field"
          // onSubmit={handleSubmit}
        >
          <div className="flex flex-col justify-center items-center gap-3 sm:w-80">
            <input id="bot-field" type="hidden" />
            <TextField
              required
              fullWidth
              size="small"
              name="name"
              label="Name"
              onChange={handleNameChange}
            />
            <TextField
              required
              fullWidth
              size="small"
              name="email"
              type="email"
              label="Email Address"
              onChange={handleEmailChange}
            />
            {/* <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={!name || !email}
            >
              Subscribe
            </Button> */}
          </div>
        </form>
      </section>
    </div>
  );
};

export default Subscribe;
